import { NextPageWithLayout } from "@bptypes/layout";
import LabelPage from "@components/pages/label/LabelPage";
import MainLayout from "@layouts/MainLayout";
import { DEFAULT_LOCALE } from "@lib/constants";
import { exportAnonSession, useSessionContext } from "@lib/context/session";
import { getLabelQuery } from "@lib/network/labels";
import {
	getLabelTopReleasesQuery,
	getReleasesQuery,
} from "@lib/network/releases";
import { getLabelTopTracksQuery } from "@lib/network/tracks";
import { getLocationData } from "@lib/utils/getLocationData";
import type { Label } from "@models/label";
import { DehydratedState, QueryClient, dehydrate, useQuery } from "@tanstack/react-query";
import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ReactElement } from "react";

interface Props {
	label?: Label;
	anonSession: AnonSession;
}

const Label: NextPageWithLayout<Props> = ({ label, anonSession }) => {
	const { getAccessToken, importAnonSession } = useSessionContext();
	importAnonSession(anonSession);
	const accessToken = getAccessToken();
	const { data: latestReleases } = useQuery(
		getReleasesQuery({
			params: {
				label_id: label?.id.toString(),
				per_page: 21,
				order_by: "-release_date",
			},
			accessToken,
		}),
	);

	const { data: top10Tracks } = useQuery(
		getLabelTopTracksQuery({
			params: { per_page: 10 },
			accessToken,
			labelId: label?.id || 0,
			number: 10,
		}),
	);

	const { data: popularReleases } = useQuery(
		getLabelTopReleasesQuery({ labelId: label?.id || 0, number: 20, accessToken }),
	);

	return (
		<LabelPage
			label={label}
			latestReleases={latestReleases?.results || []}
			top10Tracks={top10Tracks?.results || []}
			popularReleases={popularReleases?.results || []}
		/>
	);
};

export default Label;

Label.getLayout = (page: ReactElement<Props>) => {
	const labelImage: string = (page.props.label?.image.uri || "");
	return (
		<MainLayout
			title={`${page.props.label?.name} Music & Downloads on Beatport `}
			metaDescription={
				page.props.label?.bio ||
				`Download all of "${page.props.label?.name}" tracks and releases in highest quality ✚Find the latest releases here✚ #1 source for Livesets/ DJ Sets and more `
			}
			noSuffix
			hasDesktopSidebar={true}
			socialImage={labelImage}
		>
			{page}
		</MainLayout>
	);
};

type ServerSideProps = {
	dehydratedState: DehydratedState;
	anonSession: AnonSession;
	label: Label;
};

export const getServerSideProps: GetServerSideProps<ServerSideProps> = async (
	ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<ServerSideProps>> => {
	const queryClient = new QueryClient();
	const location = getLocationData(ctx.req);
	const { id } = ctx.params as { id: string };
	const labelId = id ? parseInt(id as string, 10) : 0;
	let label: Label | null = null;

	if (labelId > 0) {
		const data = await Promise.all([
			queryClient
				.fetchQuery<Label>(getLabelQuery({ labelId, location }))
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getReleasesQuery({
						params: {
							label_id: labelId.toString(),
							per_page: 21,
							order_by: "-release_date",
						},
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(
					getLabelTopTracksQuery({
						params: { per_page: 10 },
						labelId,
						number: 10,
						location,
					}),
				)
				.then((res) => res)
				.catch(() => { }),
			queryClient
				.fetchQuery(getLabelTopReleasesQuery({ labelId, number: 20, location }))
				.then((res) => res)
				.catch(() => { }),
		]);

		label = data[0] as Label;
	}

	const anonSession = await exportAnonSession();

	if (!label) {
		return {
			notFound: true,
		};
	}

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			anonSession,
			...(await serverSideTranslations(ctx.locale ?? DEFAULT_LOCALE, ["translation"])),
			label: label,
		},
	};
};
